import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Container, Form, Col, InputGroup, Row, Button } from 'react-bootstrap';

const ContainerTitle = styled.section`
    background-color: #1E2E48;
    padding:2.5% 2.5%;
    margin-top: 7rem;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`

const Index = () => {
    return (
        <>
            <ContainerTitle>
                <H2>El portal</H2>
            </ContainerTitle>
            <Container>
                <nav aria-label="breadcrumb" className="mt-5">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">INICIO</a></li>
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">BARRIOS</a></li>
                        <li className="breadcrumb-item active" aria-current="page">EL PORTAL</li>
                    </ol>
                </nav>
                <Row className="justify-content-center">
                    <Col lg={8} className="text-center">
                        <StaticImage src="../../../../images/logo-04.png" />
                        <H2 style={{ color: "#1E2E48" }} className="mt-5">Sobre Costa del Arroyo San Juan.</H2>
                        <H3>NAVEGABLE HASTA EL RIO PARANA EN LAS ALTURAS NORMALES DEL RIO</H3>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Característica:</strong> Barrio privado con terrenos residenciales de 700 m2 a 800m2, con características paisajísticas y naturales únicas, rodeado en su orientación Este, por el imponente Arroyo San Juan, ideal para actividades de pesca y náuticas. El Club House se ubica en el predio de “El Faro” Club Náutico, con piscina exterior, solárium, vestuarios, resto bar, parrillas, lagunas recreativas y jardines.</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Ubicación:</strong> Próximo al ingreso principal de El San Juan Villa Náutica</P>
                        <P className="text-dark"><strong>Superficie total común:</strong> TRESCIENTOS DIECISEIS MIL SEISCIENTOS NOVENTA Y DOS METROS CUADRADOS (316.692 m2)</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Bienes de Infraestructura:</strong> Cerramiento perimetral, Calles entoscadas, Servicios, Seguridad privada las 24 hs, 2 embarcaderos comunes, Plazas y parquizado, Club House “El Faro”.</P>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <StaticImage src="../../../../images/ElPortal.png" className="mt-5" />
                    </Col>
                    <Col lg={7}>
                        <StaticImage src="../../../../images/ElPortalTable.png" className="mt-5" />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Index;