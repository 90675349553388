import React from 'react';
import SEO from '../../structure/seo';
import Layout from '../../structure/layout';
import Index from '../../structure/pages/neighborhood/portal/index';


const ThePortal = () => {
    return (
        <Layout shadowNav={false}>
            <SEO title={'El Portal'} />
            <Index />
        </Layout>
    );
}

export default ThePortal;